<template>
  <div class="login-fade">
    <button
      v-show="!cancelation"
      v-if="step === 1"
      @click="callCancelation"
      class="back-btn"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="20"
        viewBox="0 0 11 20"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.68663 0.00102615C9.35042 0.00102615 9.01441 0.136253 8.75801 0.406494L0 9.63728L8.75801 18.8681C9.27081 19.4086 10.1024 19.4086 10.6152 18.8681C11.1283 18.3276 11.1283 17.4511 10.6152 16.9106L3.71447 9.63728L10.6152 2.36399C11.1283 1.8235 11.1283 0.946976 10.6152 0.406494C10.3588 0.136253 10.0228 0.00102615 9.68663 0.00102615Z"
          fill="#1C23C3"
        />
      </svg>
      Back
    </button>
    <div v-show="!cancelation" class="login">
      <button v-if="step === 0" @click="modalClose" class="login-close">
        <svg
          width="23"
          height="23"
          viewBox="0 0 23 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.00342 2L20.6685 20.6651"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M20.665 2L1.99993 20.6651"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
          />
        </svg>
      </button>
      <div class="login-form">
        <template v-if="step === 0">
          <p class="login-title">
            Purchase
            <template v-if="pixtokens">Pixtokens</template>
            <template v-else>Pixcoins</template>
          </p>
          <p v-if="allCoins" class="login-subtitle">
            You need {{ requiredToBuy }}
            <svg
              class="nft-icon"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.0124 23.4426C18.6467 23.4426 24.0248 18.1948 24.0248 11.7213C24.0248 5.24781 18.6467 0 12.0124 0C5.37814 0 0 5.24781 0 11.7213C0 18.1948 5.37814 23.4426 12.0124 23.4426Z"
                fill="#34A3E6"
              />
              <path
                d="M21.3554 11.5684C21.3554 16.7284 17.1724 20.9114 12.0124 20.9114C6.85243 20.9114 2.66943 16.7284 2.66943 11.5684"
                stroke="#84CCF8"
                stroke-width="1.5"
              />
              <path
                d="M12.0124 20.9114C6.85243 20.9114 2.66943 16.7284 2.66943 11.5684"
                stroke="#E4F1FA"
                stroke-width="1.5"
              />
              <path
                d="M21.3554 12.0149C21.3554 6.85488 17.1724 2.67188 12.0124 2.67188C6.85243 2.67188 2.66943 6.85488 2.66943 12.0149"
                stroke="#034166"
                stroke-width="1.5"
              />
              <path
                d="M21.3552 12.0149C21.3552 6.85488 17.1722 2.67188 12.0122 2.67188"
                stroke="#E4F1FA"
                stroke-width="1.5"
              />
              <path
                d="M6.22852 8.00977H7.24621L8.83892 11.7348V8.00977H10.0512V15.1766H9.06893L7.47584 11.2129V15.177H6.22852V8.00977Z"
                fill="white"
              />
              <path
                d="M11.1299 8.00977H14.1558V9.00974H12.5808V10.9388H13.9168V11.9561H12.5808V15.177H11.1299V8.00977Z"
                fill="url(#paint0_linear_7768_33021)"
              />
              <path
                d="M15.5639 9.0893H14.458V8.00977H18.1212V9.0893H17.033V15.177H15.5643V9.0893H15.5639Z"
                fill="white"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_7768_33021"
                  x1="5.49221"
                  y1="18.5412"
                  x2="20.3832"
                  y2="2.28208"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="white" />
                  <stop offset="1" stop-color="white" />
                </linearGradient>
              </defs>
            </svg>
            to mint your NFTS.
          </p>
          <div class="purchase-variants">
            <div
              v-for="(item, index) in coinPacks"
              :key="index"
              class="purchase-block"
              @click="nextStep(item)"
            >
              <div v-if="item.pixtokens" class="image-holder">
                <img
                  src="../../../../public/img/icons/nft_sparkle.gif"
                  width="50"
                  height="50"
                  alt="nft sparkle"
                  class="sparcle"
                />
                <img
                  class="purchase-image"
                  src="../../../../public/img/icons/purchase-icon-token.svg"
                  width="58"
                  height="51"
                  loading="lazy"
                  alt="purchase icon"
                />
              </div>
              <img
                v-else
                class="purchase-image"
                src="../../../../public/img/icons/purchase-icon.svg"
                width="65"
                height="64"
                loading="lazy"
                alt="purchase icon"
              />
              <div class="purchase-value">
                <span class="purchase-price">{{ Number(item.coins) }}</span>
                <span class="purchase-currency">
                  <template v-if="item.pixtokens">tokens</template>
                  <template v-else>px</template>
                </span>
              </div>
              <div class="purchase-buy">${{ item.price }}</div>
            </div>
          </div>
        </template>
        <template v-else>
          <p class="login-title">Payment Method</p>
          <div ref="paypal" class="payment"></div>
        </template>
      </div>
    </div>
    <div v-if="cancelation" class="login">
      <div class="login-form">
        <p class="login-title">Cancel Purchase</p>
        <p class="login-text">Are you sure you want to cancel?</p>
        <div class="buttons">
          <button class="secondary-btn" @click="modalClose">Cancel</button>
          <button class="main-btn" @click="continuePurchase">Back</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { format } from 'date-fns';
import { mapGetters } from 'vuex';

const apiUrl = process.env.BASE_URL;

export default {
  props: {
    plugin: {
      type: Boolean,
      default: false,
    },

    buyForCoins: {
      type: Boolean,
      default: false,
    },

    coinsPack: {
      type: Number,
      default: 250,
    },

    purchaseLowLimit: {
      type: Boolean,
      default: false,
    },

    pixtokens: {
      type: Boolean,
      default: false,
    },

    allCoins: {
      type: Boolean,
      default: false,
    },

    requiredToBuy: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      step: 0,
      coinPacks: [],
      bundle: {},
      script: null,
      isPixtoken: null,
      cancelation: false,
    };
  },

  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters(['currency']),
  },

  methods: {
    async getCoinPacks(pixtokens = false) {
      const token = localStorage.getItem('token');

      const requestType = pixtokens ? 'coin_packs/pixtokens' : 'coin_packs';

      const getCoins = async (page = 1) => {
        try {
          const { data } = await axios.get(
            `${apiUrl}/api/${requestType}?platform=paymentSystem&page=${page}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          data.collection = data.collection.map((item) => {
            const newItem = {
              ...item,
              pixtokens,
              coins: this.pixtokens ? item.pixtokens : item.coins,
            };

            return newItem;
          });

          return data;
        } catch (error) {
          console.log(error);
        }
      };

      const coinPacks = await getCoins();

      if (coinPacks.total_pages > 1)
        for (let page = 2; page <= coinPacks.total_pages; page++) {
          const response = await getCoins(page);
          coinPacks.collection.push(...response.collection);
        }

      return coinPacks.collection;
    },

    callCancelation() {
      this.cancelation = true;
    },

    continuePurchase() {
      this.cancelation = false;
    },

    modalClose() {
      this.$emit('modalClose');
    },

    nextStep(bundle) {
      this.isPixtoken = bundle.pixtokens;
      this.bundle = bundle;
      this.step = 1;

      this.script = document.createElement('script');
      this.script.src =
        'https://www.paypal.com/sdk/js?client-id=ATRt3Yb_sbpRmlaYmDyGD9C4b3GMo7hSJnFOLaobTw-QVFApb2NxECLKhYAKXx3JyzC4DB0ZuNQVV3xH';
      this.script.addEventListener('load', this.setLoaded);
      document.body.appendChild(this.script);
    },

    setLoaded() {
      window.paypal
        .Buttons({
          style: {
            color: 'silver',
            shape: 'pill',
            label: 'buynow',
            height: 40,
          },
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    currency_code: 'USD',
                    value: this.bundle.price,
                    breakdown: {
                      item_total: {
                        currency_code: 'USD',
                        value: this.bundle.price,
                      },
                    },
                  },
                  items: [
                    {
                      name: this.currentUser.id,
                      sku: this.bundle.product_id,
                      quantity: 1,
                      unit_amount: {
                        currency_code: 'USD',
                        value: this.bundle.price,
                      },
                    },
                  ],
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture();

            if (order.status === 'COMPLETED') {
              const orderDate = format(new Date(order.create_time), 'd LLLL y');

              localStorage.setItem('lastOrderDate', orderDate);

              if (!this.plugin) {
                const payload = {
                  active: true,
                  type: 'success',
                  text: 'Your order is now processing.',
                };

                this.$store.dispatch('setToast', payload);
              }

              if (this.plugin) {
                this.$emit(
                  'buyPlugin',
                  'pixcoins',
                  this.currency.pixcoins,
                  this.buyForCoins,
                  this.bundle
                );

                return;
              }

              this.$emit(
                'currencyUpdateCheck',
                this.isPixtoken ? 'pixtokens' : 'pixcoins',
                this.isPixtoken
                  ? this.currency.pixtokens
                  : this.currency.pixcoins,
                this.bundle
              );

              return;
            }

            const payload = {
              active: true,
              type: 'error',
              text: 'Something went wrong',
            };

            this.$store.dispatch('setToast', payload);
          },
          onError: (err) => {
            console.log(err);
          },
        })
        .render(this.$refs.paypal);
    },
  },

  async mounted() {
    document.body.style.overflow = 'hidden';

    if (this.allCoins) {
      this.coinPacks = await this.getCoinPacks();
      this.coinPacks.push(...(await this.getCoinPacks(true)));

      return;
    }

    if (this.pixtokens) {
      this.coinPacks = await this.getCoinPacks(true);

      return;
    }

    if (this.plugin && (!this.buyForCoins || this.coinsPack !== 250)) {
      this.coinPacks = await this.getCoinPacks();

      const bundle = this.coinPacks.filter(
        (pack) => Number(pack.coins) === this.coinsPack
      );

      this.nextStep(bundle[0]);

      return;
    }

    this.coinPacks = await this.getCoinPacks();

    if (this.purchaseLowLimit) {
      this.coinPacks = this.coinPacks.filter(
        (item) => Number(item.price) >= 4.99
      );

      return;
    }
  },

  destroyed() {
    document.body.removeAttribute('style');

    if (this.script) this.script.remove();
  },
};
</script>

<style scoped>
.login-title {
  margin-bottom: 7px;
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
}

@media (max-width: 767px) {
  .login-title {
    font-weight: 500;
  }
}

.login-text {
  margin: 18px auto 59px;
  color: #3a4146;
  text-align: center;
  font-family: SF Pro Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: -0.256px;
}

.login-title + .purchase-variants {
  margin-top: 30px;
}

.login-subtitle {
  margin: 0 0 30px;
  max-width: 456px;
  line-height: 20px;
  letter-spacing: -0.256px;
  text-align: center;
}

.login-fade {
  padding: 50px 20px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(242, 242, 242, 0.85);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 999;
  overflow-x: hidden;
  overflow-y: auto;
}

@media (max-width: 991px) {
  .login-fade {
    padding-top: 100px;
  }
}

.login {
  position: relative;
  display: flex;
  justify-content: center;
  align-self: center;
  margin: 0 20px;
  padding: 52px 20px;
  max-width: 456px;
  width: 100%;
  height: fit-content;
  background: #fff;
  border-radius: 15px;
  filter: drop-shadow(0px 4px 10px rgba(44, 145, 179, 0.3));
}

.login-close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 19px;
  height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
}

.purchase-variants {
  margin: 0 auto;
  max-width: 340px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.purchase-block {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 21px 10px 10px;
  width: calc(50% - 10px);
  box-shadow: 0px 12px 23px rgba(30, 73, 183, 0.213938);
  border-radius: 8px;
  cursor: pointer;
}

.purchase-value {
  margin-top: 10px;
}

.purchase-price {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #1e2541;
}

.purchase-currency {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #6d7d89;
  text-transform: uppercase;
}

.purchase-buy {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 35px;
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #fff;
  text-shadow: 0px 1px 0px #3f73c0;
  border: none;
  background: linear-gradient(309.61deg, #254fcf 23.81%, #39c2ef 107.28%);
  border-radius: 17.5px;
  cursor: pointer;
}

.custom-card-form .purchase-buy {
  height: 50px;
  font-size: 16px;
  border-radius: 23px;
}

.nft-icon {
  position: relative;
  top: 6px;
}

.login-form {
  margin: 0 auto;
  max-width: 316px;
  width: 100%;
}

.payment {
  margin-top: 35px;
}

.back-btn {
  padding: 0;
  display: flex;
  align-items: center;
  position: absolute;
  top: 50px;
  left: 25px;
  color: #1c23c3;
  font-family: SF Pro Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  background: none;
  border: none;
}

.back-btn svg {
  margin-right: 8px;
}

.buttons {
  margin-top: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-btn {
  margin-left: 29px;
  background: linear-gradient(264.92deg, #1c23c3 10.3%, #39c0ee 110.14%);
  filter: drop-shadow(0px 4px 10px rgba(44, 145, 179, 0.3));
  border-radius: 23px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: -0.3168px;
  color: #fff;
  text-shadow: 0px 1px 0px #386dbd;
  border: none;
  width: 148px;
  height: 44px;
  cursor: pointer;
}

.secondary-btn {
  margin-right: 29px;
  filter: drop-shadow(0px 4px 10px rgba(44, 145, 179, 0.3));
  border-radius: 23px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: -0.256px;
  color: #6d7d89;
  border: none;
  background: #fff;
  width: 148px;
  height: 44px;
  cursor: pointer;
}

.image-holder {
  position: relative;
}

.sparcle {
  position: absolute;
  top: -16px;
  left: -23px;
}
</style>
