import { render, staticRenderFns } from "./purchaseModal.vue?vue&type=template&id=4b3f6f38&scoped=true"
import script from "./purchaseModal.vue?vue&type=script&lang=js"
export * from "./purchaseModal.vue?vue&type=script&lang=js"
import style0 from "./purchaseModal.vue?vue&type=style&index=0&id=4b3f6f38&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b3f6f38",
  null
  
)

export default component.exports