<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__inner">
        <div class="footer__menu">
          <div>
            <router-link to="/artist" class="footer__link">
              Sell your Art
            </router-link>
            <router-link to="/" class="footer__link">The App</router-link>
          </div>
          <div>
            <router-link
              :to="{
                name: 'Artist',
                hash: '#partners',
              }"
              class="footer__link"
            >
              Partners
            </router-link>
            <router-link to="/faq" class="footer__link">FAQ</router-link>
            <p v-on:click="modalOpen" class="footer__link impresum">
              Impressum
            </p>
          </div>
          <div>
            <router-link
              to="/privacy-policy"
              class="footer__link "
              target="_blank"
            >
              Privacy Policy
            </router-link>

            <router-link to="/terms" class="footer__link " target="_blank"
              >Terms and Conditions</router-link
            >

            <a
              href="mailto:info@pixchangeapp.com?subject=Contact%20Us"
              class="button hide-xs"
            >
              Contact Us
            </a>
          </div>
          <div>
            <nav class="footer__socials">
              <a
                href="https://twitter.com/Pixchange_app"
                class="soc-item"
                target="_blank"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.0"
                  width="30"
                  height="30"
                  viewBox="0 0 126.000000 126.000000"
                  preserveAspectRatio="xMidYMid meet"
                  style="border-radius: 100%;"
                >
                  <g
                    transform="translate(0.000000,126.000000) scale(0.100000,-0.100000)"
                    fill="#000000"
                    stroke="none"
                  >
                    <path
                      fill="#fff"
                      d="M125 1241 c-22 -10 -52 -31 -67 -47 -58 -62 -58 -62 -58 -566 0 -429 2 -466 19 -503 10 -22 31 -52 47 -67 62 -58 62 -58 566 -58 426 0 466 2 503 19 51 23 101 79 114 128 7 24 11 206 11 491 0 493 0 494 -58 556 -15 16 -45 37 -67 47 -37 17 -73 19 -505 19 -432 0 -468 -2 -505 -19z m466 -366 c42 -60 81 -111 87 -113 5 -1 54 49 108 112 94 110 101 116 137 116 l37 0 -120 -140 c-66 -77 -120 -144 -120 -149 0 -5 65 -101 145 -214 80 -114 145 -209 145 -212 0 -3 -53 -4 -117 -3 l-117 3 -91 129 c-50 72 -96 131 -101 133 -5 2 -61 -58 -124 -132 -110 -130 -116 -135 -153 -135 l-38 0 88 103 c49 56 112 129 140 163 l53 61 -135 191 c-74 105 -135 194 -135 197 0 3 53 4 117 3 l117 -3 77 -110z"
                    ></path>
                    <path
                      d="M421 883 c85 -124 372 -529 384 -541 7 -8 28 -12 53 -10 l41 3 -208 295 -208 295 -47 3 -48 3 33 -48z"
                    ></path>
                  </g>
                </svg>
              </a>
              <a
                href="https://www.facebook.com/pixchangeapp"
                class="soc-item"
                target="_blank"
              >
                <img
                  src="../../../public/img/social-media-facebook.png"
                  alt="facebook"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/pixchange-app/"
                class="soc-item"
                target="_blank"
              >
                <img
                  src="../../../public/img/social-media-linkedin.png"
                  alt="linkedin"
                />
              </a>
              <a
                href="https://www.instagram.com/pixchange.app/"
                class="soc-item"
                target="_blank"
              >
                <img
                  src="../../../public/img/social-media-instagram.png"
                  alt="instagram"
                />
              </a>
              <a
                href="https://www.pinterest.ch/pixchangeapp"
                class="soc-item"
                target="_blank"
              >
                <img
                  src="../../../public/img/social-media-pinterest.png"
                  alt="pinterest"
                />
              </a>
            </nav>
            <div class="footer-apps">
              <IosLink mono />
              <AndroidLink mono />
              <a
                href="mailto:info@pixchangeapp.com?subject=Contact%20Us"
                class="button hide-lg"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer__copy copy">
      Copyright © {{ currentYear }} Pixchange. All rights reserved.
    </div>
    <div class="modal" v-if="this.modalWindow">
      <div class="modal-content">
        <span class="close" v-on:click="ModalClose">&times;</span>
        <h2>Impressum</h2>
        <div class="gmbh">
          <p>Pixchange GmbH</p>
          <p>Münstergasse 36, 3011 Bern, Switzerland.</p>
          <p>Contact: admin@pixchangeapp.com</p>
          <p>Tax N°: CHE-482.794.880</p>
        </div>
      </div>
    </div>
    <Cookie v-if="cookie" @cookieClose="cookieClose" />
  </footer>
</template>

<script>
import Cookie from "./cookie.vue";
import IosLink from "../components/ui/app-ios";
import AndroidLink from "../components/ui/app-android";

export default {
  name: "Footer",

  components: {
    Cookie,
    IosLink,
    AndroidLink,
  },

  data() {
    return {
      modalWindow: false,
      currentYear: new Date().getFullYear(),
      cookie: false,
    };
  },

  methods: {
    modalOpen() {
      this.modalWindow = true;
    },
    ModalClose() {
      this.modalWindow = false;
    },
    cookieExist() {
      const cookieItem = localStorage.getItem("pixchange_cookie");
      if (!cookieItem) {
        this.cookie = true;
      }
    },
    cookieClose() {
      this.cookie = false;
    },
  },

  mounted() {
    this.cookieExist();
  },
};
</script>

<style scoped>
.footer__menu {
  margin-bottom: 31px;
  justify-content: space-between;
}

@media (max-width: 1199px) {
  .footer__menu > div {
    width: 50%;
  }
}

.footer__btn {
  /*  background: linear-gradient(315deg, #4b83d8 0%, #84b5ed 100%);*/

  background: #ffffff;
  border-radius: 6px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  color: #000000;
}

p.impresum {
  cursor: pointer;
}

.modal h2 {
  margin-bottom: 25px;
  text-align: center;
  color: #1e2541;
}
.footer {
  margin-top: auto;
  position: relative;
  background: linear-gradient(91.94deg, #051954 0.95%, #010910 98.71%);
  font-family: Heebo;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: #ffffff;
}

.footer__copy {
  height: 43px;
  background: #000;
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 43px;
  text-align: center;
  color: #ffffff;
  mix-blend-mode: normal;
}

.modal {
  position: absolute;
  left: 50%;
  top: 10%;
  width: 40%;
  transform: translate(-50%, 0);
  color: #6d7d89;
  font-weight: 400;
  font-size: 16px;
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  font-family: "Source Sans Pro";
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.footer-apps {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  width: max-content;
  margin: 0 auto;
}

@media (max-width: 1199px) and (min-width: 768px) {
  .button {
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .hide-lg {
    display: none;
  }
}
@media (max-width: 1024px) {
  .footer-apps {
    justify-content: flex-start;
  }
}

@media (max-width: 767px) {
  .hide-xs {
    display: none;
  }

  .footer-apps {
    flex-direction: column;
    align-items: center;
  }

  .footer-apps::v-deep .app-link {
    margin: 0 0 20px 0;
  }

  .button {
    width: 139px;
  }

  .footer__menu {
    padding: 0 24px;
    flex-direction: column;
    align-items: center;
  }

  .footer__menu > div {
    width: 100%;
  }

  .footer__link {
    text-align: center;
  }

  .footer__socials {
    justify-content: center;
  }

  .footer__socials + .button {
    align-self: center;
  }
}
</style>
