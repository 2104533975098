import Vue from "vue";
import axios from "axios";
import addMetaTags from "./meta-tags/add-meta-tags.js";
import metaTags from "./meta-tags/default-tags-data";

import VueMeta from "vue-meta";
import VueRouter from "vue-router";

Vue.use(VueRouter);

Vue.use(VueMeta);

const Transaction = () => import("../pages/transaction.vue");
const UserVerification = () => import("../pages/userVerification.vue");
const SuperAdmin = () => import("../pages/superAdmin.vue");
const HomePage = () => import("../pages/home.vue");
const FaqPage = () => import("../pages/faq.vue");
const ImagePage = () => import("../pages/image.vue");
const ImagesPage = () => import("../pages/images.vue");
const TermsPage = () => import("../pages/terms.vue");
const TransactionHistory = () => import("../pages/transactionHistory.vue");
const ErrorPage = () => import("../pages/404.vue");

const ArtistPage = () => import("../pages/artist.vue");
const PrivacyPolicy = () => import("../pages/privacy-policy.vue");
const Spotlight = () => import("../pages/spotlight.vue");

const AlbumsPage = () => import("../pages/PrivateAlbumDetails.vue");
const NftPage = () => import("../pages/NftPage.vue");
const ViewOriginal = () => import("../pages/ViewOriginal.vue");
const EditNftPage = () => import("../pages/EditNftPage.vue");
const ArtistsProfile = () => import("../pages/ArtistsProfile.vue");
const EditProfile = () => import("../pages/EditProfile.vue");
const ArtistsAlbumDetails = () => import("../pages/ArtistsAlbumDetails.vue");
const Payment = () => import("../pages/payment-system.vue");
const Cashout = () => import("../pages/cashout.vue");
const AccountStatus = () => import("../pages/account-status.vue");

Vue.component("Transaction", Transaction);
Vue.component("UserVerification", UserVerification);
Vue.component("SuperAdmin", SuperAdmin);
Vue.component("HomePage", HomePage);
Vue.component("FaqPage", FaqPage);
Vue.component("ImagePage", ImagePage);
Vue.component("ImagesPage", ImagesPage);
Vue.component("AlbumsPage", AlbumsPage);
Vue.component("NftPage", NftPage);
Vue.component("ViewOriginal", ViewOriginal);
Vue.component("EditNftPage", EditNftPage);
Vue.component("TermsPage", TermsPage);
Vue.component("TransactionHistory", TransactionHistory);
Vue.component("ErrorPage", ErrorPage);
Vue.component("ArtistPage", ArtistPage);
Vue.component("PrivacyPolicy", PrivacyPolicy);
Vue.component("Spotlight", Spotlight);
Vue.component("Payment", Payment);
Vue.component("Cashout", Cashout);
Vue.component("AccountStatus", AccountStatus);

const apiUrl = process.env.BASE_URL;

const routes = [
  {
    name: "TokenRedirect",
    path: "/tokens/:id",
    beforeEnter: async (to, from, next) => {
      const { data } = await axios.get(
        `${apiUrl}/api/tokens/${to.query.image_id}`
      );

      next({
        name: data.image.is_video ? "AnimatiOnPreview" : "ViewOriginal",
        params: { id: data.external_id },
      });
    },
  },
  {
    name: "AccountStatus",
    path: "/account-status",
    component: AccountStatus,
    meta: {
      title: "Account Status",
      requiresAuth: true,
      template: "paymentTemplate",
    },
  },
  {
    name: "Payment",
    path: "/payment-system",
    component: Payment,
    meta: { title: "Payment", requiresAuth: true, template: "paymentTemplate" },
  },
  {
    name: "Cashout",
    path: "/cashout",
    component: Cashout,
    meta: { title: "Cashout", requiresAuth: true, template: "paymentTemplate" },
  },
  {
    name: "TransactionHistory",
    path: "/transaction-history",
    component: TransactionHistory,
    meta: {
      title: "Transaction History",
      requiresAuth: true,
      template: "defaultTemplate",
    },
  },
  {
    name: "UserVerification",
    path: "/user_verification",
    component: UserVerification,
    meta: { title: "Metamask User Verification", template: "metamaskTemplate" },
  },
  {
    name: "Transaction",
    path: "/transaction",
    component: Transaction,
    meta: { title: "Metamask Transaction", template: "metamaskTemplate" },
  },
  {
    name: "Sale",
    path: "/sell_offer",
    component: Transaction,
    meta: { title: "Metamask Sale", template: "metamaskTemplate" },
  },
  {
    name: "Mint",
    path: "/mint_sell",
    component: Transaction,
    meta: { title: "Metamask Mint", template: "metamaskTemplate" },
  },
  {
    name: "SuperAdmin",
    path: "/superadmin",
    component: SuperAdmin,
    meta: { template: "metamaskTemplate" },
  },
  {
    name: "HomePage",
    path: "/",
    component: HomePage,
    props: (route) => ({ query: route.query }),
    meta: {
      title: "Home",
      metaTags,
      colored: true,
      template: "defaultTemplate",
    },
  },
  {
    name: "faq",
    path: "/faq",
    component: FaqPage,
    meta: {
      title: "FAQ",
      metaTags,
      template: "defaultTemplate",
    },
  },
  {
    path: "/user-invitation/:id",
    meta: { template: "defaultTemplate" },
    beforeEnter: (to, from, next) => {
      const id = to.params.id;
      next({ name: "HomePage", query: { "user-invitation": id } });
    },
  },
  {
    name: "Profile",
    path: "/profile",
    component: ArtistsProfile,
    meta: {
      title: "Profile",
      requiresAuth: true,
      isProfile: true,
      template: "albumTemplate",
    },
  },
  {
    name: "EditProfile",
    path: "/profile/edit",
    component: EditProfile,
    meta: {
      title: "Edit Profile",
      requiresAuth: true,
      isProfile: true,
      template: "defaultTemplate",
    },
  },
  {
    name: "Spotlight",
    path: "/spotlight",
    component: Spotlight,
    meta: { template: "defaultTemplate", title: "Spotlight" },
  },
  {
    name: "ArtistPage",
    path: "/users/:id",
    component: ArtistsProfile,
    meta: {
      title: "Community",
      requiresAuth: false,
      template: "albumTemplate",
    },
    beforeEnter: (to, from, next) => {
      fetch(`${apiUrl}/api/users/${to.params.id}`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data) {
            const newData = {
              type: "Sticker",
              title: `Community | ${data.username}`,
              web_page_url: `${apiUrl}${to.fullPath}`,
              image: data.profile_image,
            };

            newData.image.versions.fixed_large = {
              width: 512,
              height: 512,
              url: data.profile_image.url,
              size: 1,
            };

            addMetaTags(newData);
          }

          next();
        })

        .catch((err) => {
          console.log(err);
        });

      next();
    },
  },
  {
    name: "ArtistsAlbumDetails",
    path: "/users/:id/albums/:albumId",
    component: ArtistsAlbumDetails,
    props: true,
    meta: {
      requiresAuth: false,
      template: "albumTemplate",
    },
    beforeEnter: (to, from, next) => {
      fetch(
        `${apiUrl}/api/users/${to.params.id}/albums/${to.params.albumId}/images`
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data) {
            const newData = data.collection[0];
            newData.title = to.query.title;
            newData.web_page_url = `${apiUrl}${to.fullPath}`;

            addMetaTags(newData);
          }

          next();
        })

        .catch((err) => {
          console.log(err);
        });

      next();
    },
  },
  {
    name: "AlbumsPage",
    path: "/albums/:albumId/images",
    component: AlbumsPage,
    props: true,
    meta: {
      title: "Profile",
      isProfile: true,
      template: "albumTemplate",
    },
  },
  {
    name: "NftCollection",
    path: "/collections/:collectionId",
    component: NftPage,
    props: true,
    meta: {
      title: "Collection",
      isProfile: true,
      template: "defaultTemplate",
    },
    // beforeEnter: async (to, from, next) => {
    // const { data } = await axios.get(
    //   `${apiUrl}/api/collections/${to.params.collectionId}`
    // );

    // data.nft_status !== "minted"
    //   ? next()
    //   : next({
    //     name: "MintedCollection",
    //     params: {
    //       collectionId: data.external_address,
    //     },
    //   });
    // },
  },
  {
    name: "MintedCollection",
    path: "/minted_collection/:collectionId",
    component: NftPage,
    props: true,
    meta: {
      title: "Collection",
      isProfile: true,
      template: "defaultTemplate",
    },
    beforeEnter: (to, from, next) => {
      fetch(`${apiUrl}/api/collections/${to.params.collectionId}`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data) {
            const newData = {
              type: "Sticker",
              title: `Collection ${data.title}`,
              web_page_url: `${apiUrl}${to.fullPath}`,
              image: data.profile_image,
            };

            newData.image.versions.fixed_large = {
              width: 512,
              height: 512,
              url: data.profile_image.url,
              size: 1,
            };

            addMetaTags(newData);
          }

          next();
        })

        .catch((err) => {
          console.log(err);
        });

      next();
    },
  },
  {
    name: "ViewOriginal",
    path: "/vieworiginal/:id",
    component: ViewOriginal,
    props: true,
    meta: {
      title: "Details",
      template: "defaultTemplate",
    },
    beforeEnter: (to, from, next) => {
      fetch(`${apiUrl}/api/images/${to.params.id}`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data) {
            addMetaTags(data);
          }
          next();
        })

        .catch((err) => {
          console.log(err);
        });

      next();
    },
  },
  {
    name: "AnimatiOnPreview",
    path: "/animationpreview/:id",
    component: ViewOriginal,
    props: true,
    meta: {
      title: "Details",
      template: "defaultTemplate",
    },
    beforeEnter: (to, from, next) => {
      fetch(`${apiUrl}/api/images/${to.params.id}`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data) {
            addMetaTags(data);
          }
          next();
        })

        .catch((err) => {
          console.log(err);
        });
      next();
    },
  },
  {
    name: "CreateNftCollection",
    path: "/create-collection",
    component: EditNftPage,
    props: true,
    meta: {
      title: "Create NFT Collection",
      requiresAuth: true,
      isProfile: true,
      template: "defaultTemplate",
    },
  },
  {
    name: "EditNftCollection",
    path: "/collections/:id/edit",
    component: EditNftPage,
    props: true,
    meta: {
      title: "Edit NFT Collection",
      requiresAuth: true,
      isProfile: true,
      template: "defaultTemplate",
    },
  },
  {
    name: "ImagesPage",
    path: "/images",
    component: ImagesPage,
    meta: {
      title: "Images",
      template: "defaultTemplate",
    },
  },
  {
    name: "image",
    props: true,
    path: "/images/:id",
    component: ImagePage,
    meta: { title: "Collection", template: "defaultTemplate" },
    beforeEnter: (to, from, next) => {
      fetch(`${apiUrl}/api/images/${to.params.id}`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data) {
            addMetaTags(data);
          }
          next();
        })

        .catch((err) => {
          console.log(err);
        });
      next();
    },
  },
  {
    name: "terms",
    path: "/terms",
    component: TermsPage,
    meta: {
      title: "Terms",
      metaTags,
      template: "defaultTemplate",
    },
  },
  {
    path: "/404",
    name: "error-page",
    component: ErrorPage,
    meta: { template: "defaultTemplate" },
  },
  {
    name: "Artist",
    path: "/artist",
    component: ArtistPage,
    meta: {
      title: "Artist",
      metaTags,
      colored: true,
      template: "defaultTemplate",
    },
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
    meta: {
      title: "Privacy Policy",
      metaTags,
      template: "defaultTemplate",
    },
  },

  { path: "*", component: ErrorPage, meta: { template: "defaultTemplate" } },
];

export default routes;
